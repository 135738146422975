import { types, flow, addMiddleware } from 'mobx-state-tree';
import isbot from 'isbot';
import { getPropInUrlParams, hasPropInUrlParams } from '../utils/UrlParams';
import { createClient } from '../graphql/client';
import { getExternalSettings } from '../utils/GetExternalSettings';
import { PAYABLE_SETTINGS_LIST } from '../constants/payableSettingsList';
import {
  eventNames,
  baseProperties,
  authSuccessProperties,
  loginPropertyNames
} from '../constants/mixpanel';
import PayablesModel from './PayablesModel';
import PaymentsHistory from './PaymentsHistory';
import TranslationsModel from './Translations';
import SettingsModel from './SettingsModel';
import UserState from './UserState';
import SessionParams from '../utils/SessionParams';
import RouterModel from './RouterModel';
import PastStatementsModel from './PastStatements';
import MembersEmailAddresses from './MembersEmailAddresses';
import MembersAddresses from './MembersAddress';
import MembersPhoneNumber from './MembersPhoneNumber';
import InsuranceInformationModel from './InsuranceInformation';
import TrackingModel from './TrackingModel';
import MixpanelModel from './MixpanelModel';
import ProviderLinks from './ProviderLinks';
import ProviderName from './ProviderName';
import JsErrorReporter from './JsErrorReporter';
import PaymentModel from './PaymentModel';
import OnPatchListener from './helpers/OnPatchListener';
import IvyChatViewModel from './IvyChatView';
import SessionModel from './SessionModel';
import Provider from './Provider';
import EmbeddedModel from './EmbeddedModel';
import PatientConsent from './PatientConsent';
import Curae from './Curae';
import AccountLookup from './AccountLookup';
import FullOffers from './FullOffers';
import ResourcesModel from './Resources';
import Command from './Command';
import { changeLoginStateInAllTabs } from '../utils/Broadcast';

const unauthorizedRequestMessages = [
  'GraphQL error: Unauthorized Request',
  'GraphQL error: Session Expired/Account Not Logged In'
];
const unauthorizedRequestMessagesSso = [
  'GraphQL error: Session Expired/Account Not Logged In SSO Redirect'
];

const {
  IS_BOT,
  IS_BOT_NAME,
  PLATFORM,
  SOURCE,
  PROVIDER,
  FACILITY,
  LANGUAGE,
  REVENUE_MODEL
} = baseProperties;
const { AUTHENTICATION_FIELDS } = authSuccessProperties;
const rootModel = types
  .model('MainStore', {
    appSettings: types.frozen({ serverPath: '', basename: '', buildType: '' }),
    pastStatements: types.optional(PastStatementsModel, {}),
    payablesModel: types.optional(PayablesModel, {}),
    paymentsHistory: types.optional(PaymentsHistory, {}),
    insuranceInformationModel: types.optional(InsuranceInformationModel, {}),
    emailAddressesModel: types.optional(MembersEmailAddresses, {}),
    addressDetailsModel: types.optional(MembersAddresses, {}),
    phoneNumberModel: types.optional(MembersPhoneNumber, {}),
    command: types.optional(Command, {}),
    router: types.optional(RouterModel, {}),
    settings: types.optional(SettingsModel, {}),
    session: types.optional(SessionModel, {}),
    translations: types.optional(TranslationsModel, {}),
    tracking: types.optional(TrackingModel, {}),
    mixpanelModel: types.optional(MixpanelModel, {}),
    providerName: types.optional(ProviderName, {}),
    providerLinks: types.optional(ProviderLinks, {}),
    provider: types.optional(Provider, {}),
    paymentModel: types.optional(PaymentModel, {}),
    jsErrorReporter: types.optional(JsErrorReporter, {}),
    embeddedModel: types.optional(EmbeddedModel, {}),
    userState: types.optional(UserState, {}),
    dataLoaded: types.optional(types.boolean, false),
    hasUnauthorizedError: types.optional(types.boolean, false),
    isReloadingPayables: types.optional(types.boolean, false),
    hasCriticalError: types.optional(types.boolean, false),
    ivyChatView: types.optional(IvyChatViewModel, {}),
    patientConsentModel: types.optional(PatientConsent, {}),
    curaeModel: types.optional(Curae, {}),
    accountLookup: types.optional(AccountLookup, {}),
    fullOffersModel: types.optional(FullOffers, {}),
    resources: types.optional(ResourcesModel, {}),
    isNotLoggedInUserIntialized: types.optional(types.boolean, false)
  })
  .actions(self => ({
    initMiddleware: () => {
      //listen to API errors on each child model and redirect to error page
      addMiddleware(self, (call, next, abort) => {
        if (
          call.context &&
          call.context.hasApiError &&
          call.context.apiErrorType &&
          call.context.isCriticalError
        ) {
          const { apiErrorType, errorReport } = call.context;
          if (!self.hasCriticalError) {
            //check if error is an unauthorized request error which requires redirection to logoutLink
            let errorMessage = apiErrorType.message;
            let isUnauthorized =
              unauthorizedRequestMessages.includes(errorMessage);
            let isUnauthorizedSSO =
              unauthorizedRequestMessagesSso.includes(errorMessage);
            if (isUnauthorized || isUnauthorizedSSO) {
              self.handleUnauthorizedRequest(errorReport, isUnauthorizedSSO);
              //abort();
              //return;
            } else {
              self.setErrorStateAndRedirect(errorReport);
            }
          }
        }
        next(call);
      });
    },
    setErrorStateAndRedirect: errorMessage => {
      self.redirectToErrorPage();
      if (self.hasUnauthorizedError) return;
      if (!self.dataLoaded) {
        self.setCriticalError(true);
        self.setDataLoaded(true);
      }
      self.reportError(errorMessage);
      self.handleNoProvider();
    },
    reportError: errorReport => {
      const errorOnProvider = self.provider.internalName || 'missing';
      self.jsErrorReporter.sendReport({
        message: `${errorReport.errorMessage} query: ${errorReport.query} provider: ${errorOnProvider}`,
        stack: errorReport.errorMessage?.stack,
        clientActions: self.userState.userActions.toString()
      });
    },
    redirectToErrorPage: () => {
      self.router.history.push('/error');
    },
    redirectToPageletErrorPage: () => {
      console.log(`Pagelet error --- ${self.embeddedModel.errors}`);
      self.hasCriticalError = false;
      self.session.setIsLoginProcessDone();
      self.setDataLoaded(true);
    },
    initializeNotLoggedInUser: flow(function* () {
      if (self.isNotLoggedInUserIntialized) {
        return;
      }
      yield self.settings.loadWithoutSession(self.provider.internalName);
      if (self.patientConsentModel.isConsentPage) {
        self.setDataLoaded(true);
      } else {
        yield self.session.setPreAuthenticationIdentifier(
          self.provider.internalName
        );
        self.initializeTrackingModel();
      }
      self.isNotLoggedInUserIntialized = true;
    }),
    fetchInitialRequests: flow(function* () {
      //get server path from external json
      yield self.fetchAndSetClientUri();
      self.router.setBaseName(self.appSettings.basename);

      ////
      if (!self.embeddedModel.isPageletMode) {
        if (
          hasPropInUrlParams('embedded') &&
          getPropInUrlParams('embedded') == 'true'
        ) {
          yield self.embeddedModel.loadCernerSdk(null, {
            targetSelectors: '.App'
          });
        }
        yield self.getActualProviderName();
        yield self.translations.loadInitialTranslation(self.provider);
        if (self.patientConsentModel.isConsentPage) {
          self.session.setIsLoggedIn(false);
        } else {
          yield self.session.checkIsLoggedIn(self.provider.internalName);
        }
        if (!self.session.isLoggedIn) {
          yield self.initializeNotLoggedInUser();
        }
      } else {
        //Cerner pagelet mode
        try {
          if (!self.embeddedModel.sessionId) {
            yield self.embeddedModel.getTokenAndProviderName();
          }
        } catch (e) {
          return self.redirectToPageletErrorPage();
          // in case of error we will raise the pegelet with msg "We were unable to locate your account at this time."
        }
        //re-create client with authentication headers
        if (!self.embeddedModel.pageletErrors) {
          yield createClient(self.getServerPath(), {
            authorization: self.embeddedModel.sessionId,
            provider: self.embeddedModel.providerInternalName,
            payables: self.payablesModel.payables[0],
            requestId: self.session.requestId
          });
          yield self.translations.loadInitialTranslation(
            self.embeddedModel.pageletProviderName,
            self.embeddedModel.pageletLocale
          );
          self.settings.getThemeProperties();
          self.session.setIsLoggedIn(true);
        } else {
          self.redirectToPageletErrorPage();
        }
      }
    }),
    listenToTranslationChange: () => {
      OnPatchListener.register(
        { path: '/translations/currentLanguage' },
        () => {
          self.providerLinks.setLanguage(self.translations.currentLanguage);
        },
        true
      );
    },
    listenToFullOffersChange: () => {
      OnPatchListener.registerList(
        [
          { path: '/fullOffersModel/payzenOffers' },
          { path: '/payablesModel/payables/0/fullOffers/payzenOffers' }
        ],
        () => {
          const offers =
            self.fullOffersModel?.payzenOffers ??
            self.payable?.fullOffers?.payzenOffers;
          offers && self.payable.applyFullOffers(offers);
        },
        true
      );
    },
    logout: flow(function* () {
      yield self.initializeNotLoggedInUser();
      self.router.history.push('/login');
      self.payablesModel.reset();
      self.userState.reset();
      self.session.reset();
    }),
    handleLoginChanges: flow(function* () {
      if (self.session.isLoggedIn) {
        yield self.fetchSettingsAndProviderData();
        yield self.fetchPayables();
        const payableIds = self.payablesModel.payables.map(
          payable => payable.id
        );
        const shouldSendDuration = !self.embeddedModel.isPageletMode;
        self.session.setIsLoginProcessDone(
          self.provider.internalName,
          self.provider.companyInternalName,
          payableIds,
          shouldSendDuration
        );
        if (self.session.isLoggedInWithJwtToken) {
          self.initializeTrackingModel();
        }
        if (self.showPayablePicker) {
          self.initializeTrackingModel(
            { hasAffiliates: self.payablesModel.hasAffiliates },
            eventNames.PAYABLE_PICKER
          );
        }
      } else if (self.settings.get('SUS_1645_logout')) {
        yield self.logout();
      }
    }),
    listenToIsLoggedInChange: () => {
      OnPatchListener.register(
        {
          path: '/session/isLoggedIn'
        },
        self.handleLoginChanges,
        true
      );
    },
    listenToPageChange: () => {
      OnPatchListener.register(
        { path: '/router/routerStateModel/location' },
        () => {
          const { pathname } = self.router.routerStateModel.location;
          self.ivyChatView.loadChatViewIfNeeded(pathname);
          if (self.isReloadingPayables && pathname === '/bills') {
            self.setDataLoaded(false);
          }
        },
        true
      );
    },
    getServerPath: () => {
      try {
        return (
          self.appSettings.serverPath ||
          window.location.href.match(/.+?(?=\/app\/)/)[0] + '/graphql'
        );
      } catch (e) {
        return '';
      }
    },
    fetchAndSetClientUri: flow(function* () {
      try {
        self.appSettings = yield getExternalSettings();
        yield createClient(self.getServerPath(), {
          requestId: self.session.requestId
        });
        return Promise.resolve();
      } catch (e) {
        console.error(e);
        return Promise.reject(e);
      }
    }),
    fetchSettingsAndProviderData: () => {
      return new Promise(resolve => {
        const onDataLoaded = () => {
          self.settings.setFallbackLanguages(
            self.translations.translationsAsList
          );
          resolve();
        };
        OnPatchListener.registerList(
          [
            { path: '/providerLinks/dataApplied', value: true },
            { path: '/settings/dataApplied', value: true }
          ],
          onDataLoaded
        );
        self.providerLinks.load();
        self.settings.load();
        self.handleNoProvider(); //not waiting for response
      });
    },
    fetchPastStatements: flow(function* () {
      try {
        SessionParams.payableId = self.payable.id;
        SessionParams.accountNumber = self.payable.accountNumber;
        yield self.pastStatements.load();
      } catch (error) {
        if (self.embeddedModel.isPageletMode) {
          self.hasCriticalError = false;
          self.redirectToPageletErrorPage();
        } else {
          self.setErrorStateAndRedirect({ errorMessage: error });
        }
      }
    }),

    fetchPayables: flow(function* () {
      try {
        const showPastBills = self.settings.getShowPastBills() || false;
        const withAppointments = self.settings.get('pre_service') || false;
        // if showPastBills is true it means we dont want to filter closed bills
        yield self.payablesModel.load(!showPastBills, withAppointments);

        //here - decide on payable - multiple payables picker or default to only one in list
        if (!self.hasCriticalError) {
          if (!self.showPayablePicker) {
            const hasSetPayable = yield self.payablesModel.setPayable();
            if (hasSetPayable) {
              yield self.fetchSettingsAndProviderData();
            }
            self.fetchSubsequentRequests();
          } else {
            // User needs to pick payable
            self.onInitialDataLoaded();
          }
        }
      } catch (error) {
        if (self.embeddedModel.isPageletMode) {
          self.hasCriticalError = false;
          self.redirectToPageletErrorPage();
        } else {
          self.setErrorStateAndRedirect({ errorMessage: error });
        }
      }
    }),
    selectPayable: flow(function* (id) {
      //this will show the loader and hide the UI
      self.setDataLoaded(false);
      yield self.payablesModel.setPayable(id);
      if (self.embeddedModel.isPageletMode) {
        yield createClient(self.getServerPath(), {
          provider: self.payable.payee.provider.internalName,
          authorization: self.embeddedModel.sessionId,
          requestId: self.session.requestId
        });
      }
      yield self.fetchSettingsAndProviderData();
      self.fetchSubsequentRequests();
      yield self.fetchPastStatements();
    }),
    onPaymentComplete: flow(function* () {
      const delay = ms => new Promise(res => setTimeout(res, ms));

      self.isReloadingPayables = true;
      self.paymentModel.onPaymentComplete();
      self.emailAddressesModel.load();
      if (self.paymentModel.isPPv4) {
        yield delay(1000);
        console.log('Waited 1s');
      }
      const showPastBills = self.settings.getShowPastBills() || false;
      const withAppointments = self.settings.get('pre_service') || false;
      yield self.payablesModel.reload(
        self.initPayableWithSettings,
        !showPastBills,
        withAppointments
      );
      self.setGetPlanSelectedData();
      self.isReloadingPayables = false;
      self.setDataLoaded(true);
    }),
    setGetPlanSelectedData: () => {
      self.paymentModel.setGetPlanSelectedData(
        self.payable.getPlanSelectedData
      );
    },
    initPayableWithSettings: payable => {
      //TODO: with so many settings being passed to Payable, maybe it's best to expose all of them with one function
      payable.initWithSettings(
        self.settings.getSettingsByList(PAYABLE_SETTINGS_LIST)
      );
      payable.setReportError(self.reportError);
    },
    fetchSubsequentRequests: flow(function* () {
      self.initPayableWithSettings(self.payable);
      self.payable.setPageletMode(
        self.embeddedModel.isPageletMode && !self.embeddedModel.isEmbeddedPage
      );
      try {
        self.translations.setDefaultValues({
          providerName: self.payable.payee.provider.name
        });
        SessionParams.payableId = self.payable.id;
        SessionParams.accountNumber = self.payable.accountNumber;
        SessionParams.provider = self.payable.payee.provider.internalName;
        SessionParams.providerName = self.payable.payee.provider.name;
        SessionParams.accountId = self.payable.accountId;

        self.providerLinks.setProvider(SessionParams.provider);
        self.paymentModel.setIsPPv4(!!self.settings.get('payment_plan_v4'));
        self.paymentModel.setSupportAppointments(
          !!self.settings.get('pre_service')
        );
        self.setGetPlanSelectedData();
        self.settings.get('care_credit_digital_buy') &&
          self.paymentModel.careCreditModel.lookup();
      } catch (e) {
        console.log('RootStore error:', e);
        //something went wrong setting provider params, redirect to error page
        self.redirectToErrorPage();
        return;
      }
      self.initializeIvyChatView();
      if (self.hasUnauthorizedError || self.hasCriticalError) return;

      self.onInitialDataLoaded();
      self.setTracking();
      //load email settings to get relevant message alert + get memberId
      SessionParams.memberId = yield self.emailAddressesModel.load();
      self.paymentModel.setMemberId(SessionParams.memberId);
    }),
    onInitialDataLoaded: () => {
      if (self.translations.translationsLoaded) {
        //this will show the UI
        self.setDataLoaded(true);
      } else {
        OnPatchListener.register(
          { path: '/translations/translationsLoaded' },
          () => self.setDataLoaded(true)
        );
      }
    },
    setTracking: flow(function* () {
      //if tracking initialized by this point - it's from new login
      if (!self.tracking.wasInitialized) {
        yield self.mixpanelModel.load();
        self.tracking.initialize(self.appSettings.buildType);
        //identify first with distinctId received from server
        self.tracking.identify(self.mixpanelModel.distinctId);
      }
      self.tracking.setInitialTimer();
      const selectBills =
        !!self.settings.get('select_a_bill') && self.payable.totalBills > 0;
      self.mixpanelModel.setAuthFields({
        ...self.payable.payableData,
        hasAffiliates: self.payablesModel.hasAffiliates,
        selectBills,
        payzenOn: self.settings.get('payzen')
      });
      self.tracking.register(self.mixpanelModel.authFields);
      self.mixpanelSuccessfulLoginEvents();
    }),
    mixpanelSuccessfulLoginEvents: () => {
      const { REMEMBER_ME } = loginPropertyNames;
      const rememberMeSetting = self.settings.remember_me_default_value;

      const trackingData = {
        [REMEMBER_ME]: self.session.loginModel.rememberMe
      };
      self.tracking.track(
        eventNames.LOGIN_SUCCESS,
        !rememberMeSetting?.disabled ? trackingData : null
      );
      self.tracking.identify(
        SessionParams.provider + '_' + SessionParams.payableId
      );
      self.tracking.track(eventNames.AUTHENTICATION_SUCCESS);
    },
    handleNoProvider: flow(function* () {
      if (!SessionParams.provider) {
        SessionParams.provider = yield self.providerName.load();
        self.providerLinks.setProvider(SessionParams.provider);
      }
    }),
    handleUnauthorizedRequest: flow(function* (errorReport, isSSO) {
      self.setCriticalError(true);
      self.setUnauthorizedError(true);
      yield self.handleNoProvider();
      let logoutLink;
      //check if we need to redirect to a specific url
      if (isSSO) {
        const ssoRedirectSetting = self.settings.get('sso_login_redirection');
        if (ssoRedirectSetting) {
          logoutLink = ssoRedirectSetting.redirection_error_link;
          logoutLink &&
            !logoutLink.startsWith('https://') &&
            !logoutLink.startsWith('http://') &&
            (logoutLink = 'https://' + logoutLink);
        }
      }
      //session expired, redirect to login page
      !logoutLink && (logoutLink = self.providerLinks.logoutLink);
      if (logoutLink && logoutLink !== window.location.href) {
        const logoutByPatientGw = self.settings.get('SUS_1645_logout');
        if (logoutByPatientGw) {
          self.session.setIsLoggedIn(false);
        } else {
          window.location = logoutLink;
        }
      } else {
        console.log('---cannot redirect to logout link---');
        self.redirectToErrorPage();
      }
    }),
    setDataLoaded: val => (self.dataLoaded = val),
    setUnauthorizedError: val => (self.hasUnauthorizedError = val),
    setCriticalError: val => (self.hasCriticalError = val),
    afterCreate: () => {
      OnPatchListener.init(self);
      changeLoginStateInAllTabs();
      self.initMiddleware();
      self.listenToIsLoggedInChange();
      self.listenToTranslationChange();
      self.listenToPageChange();
      self.fetchInitialRequests();
      self.listenToFullOffersChange();
    },
    getActualProviderName: flow(function* () {
      yield self.providerName.load();
      const providerName = self.providerName.name;
      console.log('provider name:', providerName);
      try {
        yield self.provider.loadWithoutSession(providerName);
      } catch (e) {
        console.log('could not load provider details without session', e);
      }
    }),
    setMixpanelData: () => {
      const isBot = isbot(navigator.userAgent);
      let isBotName = [];
      if (isBot) {
        const userAgentValues = navigator.userAgent.split(' ');
        isBotName = userAgentValues.filter(value => value.match(/bot/gi));
      }
      const revenueModelType =
        self.settings.get('revenue_model_type')?.revenue_model_type ||
        'Disabled';
      const newMixPanelData = {
        [IS_BOT]: isBot,
        [IS_BOT_NAME]: isBotName[0] || null,
        [PLATFORM]: navigator.platform,
        [SOURCE]: self.session.sessionSource,
        [AUTHENTICATION_FIELDS]: self.session.authenticationBy,
        [FACILITY]: self.provider.internalName,
        [PROVIDER]: self.provider.companyInternalName,
        [LANGUAGE]: self.translations.currentLanguage,
        [REVENUE_MODEL]: revenueModelType
      };
      self.mixpanelModel.setData(newMixPanelData);
    },
    initializeTrackingModel: (
      authFields = {},
      eventName = eventNames.LOGIN
    ) => {
      self.tracking.initialize(self.appSettings.buildType);
      self.setMixpanelData();
      self.mixpanelModel.setAuthFields(authFields, false);
      self.tracking.register(self.mixpanelModel.authFields);
      self.initializeIvyChatView();
      self.setDataLoaded(true);
      self.tracking.track(eventName);
    },
    initializeIvyChatView: () => {
      if (!self.ivyChatView.wasInitialized) {
        const ivyChatSetting = self.settings.get('ivy_chat_view');
        if (ivyChatSetting) {
          self.ivyChatView = IvyChatViewModel.create(ivyChatSetting);
        }
      }
    }
  }))
  .views(self => ({
    get payable() {
      return self.payablesModel ? self.payablesModel.currentPayable : null;
    },
    get showPayablePicker() {
      return (
        self.payablesModel.haveMultiplePayablesToShow &&
        !self.embeddedModel.isPageletMode
      );
    }
  }));

export default rootModel;
