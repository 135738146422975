import { flow, types } from 'mobx-state-tree';

import ApiModel from './base/ApiModel';
import { GET_PREVIOUS_PAYMENTS } from '../graphql/queries';
import SessionParams from '../utils/SessionParams';
import { findKeyByValue } from '../utils/Object';

const paymentStatuses = { paid: 0, scheduled: 1, cancelled: 2 };

const paymentTypes = {
  CREDIT_CARD: 0,
  CREDIT_CARD_VOID: 1,
  CREDIT_CARD_CHARGEBACK: 2,
  ECHECK: 3,
  ECHECK_VOID: 4,
  ECHECK_BOUNCE: 5,
  CASH: 6,
  CASH_RETURN: 7,
  PAYPAL: 8,
  CREDIT_CARD_FEE: 9,
  ECHECK_FEE: 10,
  CREDIT_CARD_FEE_VOID: 11,
  ECHECK_FEE_VOID: 12,
  DISCOUNT: 13,
  DISCOUNT_VOID: 14,
  FINANCING: 15,
  MANUAL_CHECK: 16
};

const PaymentMethod = types.model('PaymentMethod', {
  last4: types.maybeNull(types.string),
  paymentSubType: types.maybeNull(types.string),
  paymentType: types.number
});

const Payment = types
  .model('Payment', {
    amount: types.number,
    date: types.string,
    paymentMethod: types.maybeNull(PaymentMethod),
    status: types.number
  })
  .views(self => ({
    get paymentStatus() {
      return findKeyByValue(paymentStatuses, self.status);
    }
  }));
const PaymentsHistory = types
  .model('PaymentsHistoryModel', {
    previousPayments: types.maybeNull(types.array(Payment)),
    paymentTypes: types.frozen(paymentTypes),
    //property of ApiModel, expose here for easier interfacing (used by PaymentHistory component)
    dataApplied: types.optional(types.boolean, false)
  })
  .actions(self => ({
    load: flow(function* () {
      console.log('loading Payments...');
      try {
        self.dataApplied = false;
        const { previousPayments } = yield self.query(GET_PREVIOUS_PAYMENTS, {
          payableIds: [SessionParams.payableId]
        });
        self.applyWithApiStatus({ previousPayments });
        console.log('done loading Payments');
        return Promise.resolve();
      } catch (e) {
        console.error('Payments Model error', e);
        return Promise.reject();
      }
    })
  }));

export default types.compose(ApiModel, PaymentsHistory);
